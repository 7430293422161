import styled from 'styled-components'

export const Tag = styled.div`
  display: inline-block;
  padding: 4px 7px;
  border-radius: 15px;
  font-size: 12px;
  margin-left: 5px;
  &.configured {
    color: #28a745;
    border: 1px solid #28a745;
  }
  &.primary {
    color: #007bff;
    border: 1px solid #007bff;
  }
`
