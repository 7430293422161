import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { notify, useFetch, usePost } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import CardLoading from '../../../components/component-items/loading-popover'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { TableCell } from '../../../components/component-items/datatable'
import GenericModal from '../../../components/component-items/modal'
import Pager, { Pages } from '../../../components/component-items/pager'
import { SearchBox } from '../../../components/component-items/search'

import { TableRow } from './styles'

type ShipmentListProps = {
  open: boolean
  setOpen: (open: boolean) => void
  manifestId?: number
}

const ItemsCell = ({ items, orderItems }: any) => {
  return (
    <GlobalStyles.DataTable>
      <tbody>
        {orderItems
          .filter(({ sku }: any) => items.filter((i: any) => i.sku === sku).length)
          .map(({ id, img, name, sku, qty }: any) => (
            <TableRow key={id}>
              <TableCell center input={<img src={img} style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }} />} />
              <TableCell input={<Link to={`/product/variant/${id}/`}>{sku}</Link>}>
                <div>{name}</div>
              </TableCell>
              <TableCell input={qty} center />
            </TableRow>
          ))}
      </tbody>
    </GlobalStyles.DataTable>
  )
}

export const ShipmentList = ({ open, setOpen, manifestId }: ShipmentListProps) => {
  const [page, setPage] = useState(1)
  const tableRowOptions = [20, 50, 100, 250, 500]
  const [rows, setRows] = useState(tableRowOptions[0])
  const [search, setSearch] = useState('')

  const defaultQueryParamsString: any = {
    limit: rows,
    offset: (page - 1) * rows,
    q: search,
  }
  const [loading, setLoading] = useState(false)

  const {
    state: { csrf },
  } = globalState()

  const { response, loaded, error, placeholder } = useFetch(
    `/api/postage/manifest/${manifestId}/shipments/` +
      `?${Object.keys(defaultQueryParamsString)
        .map((key) => `${key}=${defaultQueryParamsString[key]}`)
        .join('&')}`,
    {}
  )
  const shipments = loaded ? response.results : []
  const total = loaded ? response.count : 0
  const totalPages = loaded && total ? Math.ceil(total / rows) : 1

  useEffect(() => {
    if (loaded && !error) {
      setLoading(!loaded)
    }
  }, [loaded])

  const handleExportShipments = async () => {
    const reload = false
    const stringifyBody = true
    const return_response = true
    const resp = await usePost(
      `/api/postage/manifest/${manifestId}/shipments/download/`,
      {},
      csrf,
      reload,
      stringifyBody,
      return_response
    )
    if (resp.success) {
      notify({
        type: 'success',
        message: 'Shipments have been requested, you will receive an email with the CSV file once it is ready.',
      })
    }
  }

  return (
    <GenericModal heading="Shipment List" size="xl" show={open} onHide={() => setOpen(false)}>
      <GlobalStyles.FullPageCard>
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>
              Shipments<small>{total} Total</small>
            </h3>
          </GlobalStyles.CardTitle>
          <GlobalStyles.CardToolbar>
            <SearchBox
              style={{ marginLeft: '1em' }}
              {...{ search, setSearch, loading, setLoading }}
              setFocus
              updateURL
            />
            <GlobalStyles.Button className="secondary" onClick={handleExportShipments}>
              Export As CSV
            </GlobalStyles.Button>
          </GlobalStyles.CardToolbar>
        </GlobalStyles.CardHeader>
        <div style={{ minHeight: 500, overflow: 'auto' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text center">Order Number</span>
                </th>
                <th>
                  <span className="text center">Created</span>
                </th>
                <th>
                  <span className="text center">Address</span>
                </th>
                <th>
                  <span className="text center">Tracking number</span>
                </th>
                <th>
                  <span className="text center">Shipment Items</span>
                </th>
                <th>
                  <span className="text center">Status</span>
                </th>
                <th>
                  <span className="text center">Dimensions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {loaded
                ? shipments.map(
                    (
                      {
                        order_id,
                        order: { address, email, shipping, items: orderItems },
                        last_tracking_update,
                        name,
                        created,
                        items,
                        length,
                        width,
                        height,
                        weight,
                      }: any,
                      k_id: number
                    ) => (
                      <TableRow key={k_id}>
                        <TableCell
                          center
                          input={
                            <Link to={`/order/${order_id}/`} style={{ maxWidth: 150 }}>
                              {order_id}
                            </Link>
                          }
                        />
                        <TableCell center input={<div style={{ minWidth: 80 }}>{created.date}</div>}>
                          <div>{created.time}</div>
                        </TableCell>
                        <TableCell input={name}>
                          {address ? (
                            <>
                              <div>
                                {address.line1}
                                {address.line2 ? ' - ' + address.line2 : ''} {address.state} {address.zip}{' '}
                                {address.country_code}
                              </div>
                              <div>
                                {email} | {address.phone}
                              </div>
                            </>
                          ) : null}
                        </TableCell>
                        <TableCell input={shipping.method}>
                          {
                            <>
                              <div>{shipping.tracking_method}</div>
                              <div>
                                <a href={shipping.tracking_url} rel="noreferrer" target="__blank">
                                  {shipping.tracking}
                                </a>
                              </div>
                            </>
                          }
                        </TableCell>
                        <TableCell>
                          {items.length ? (
                            <div
                              style={{
                                maxHeight: 200,
                                overflow: 'scroll',
                                boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                              }}
                            >
                              <ItemsCell items={items} orderItems={orderItems} />
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell
                          center
                          input={
                            last_tracking_update?.status && (
                              <StatusBoxStyled style={{ width: 132, margin: 'auto auto 10px' }} className="blue">
                                {last_tracking_update.status}
                              </StatusBoxStyled>
                            )
                          }
                        >
                          {last_tracking_update?.status_detail && (
                            <StatusBoxStyled style={{ width: 132, margin: 'auto' }} className="blue">
                              {last_tracking_update.status_detail}
                            </StatusBoxStyled>
                          )}
                        </TableCell>
                        <TableCell
                          center
                          input={
                            <div>
                              <div>
                                {length} x {width} x {height}
                              </div>
                              <div>{weight} lbs</div>
                            </div>
                          }
                        />
                      </TableRow>
                    )
                  )
                : null}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
        <GlobalStyles.CardFooter>
          <Pages page={page} setPage={setPage} total={totalPages} />
          <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
        </GlobalStyles.CardFooter>
        {!loaded ? <CardLoading error={error} /> : null}
      </GlobalStyles.FullPageCard>
      {loading ? <CardLoading text={placeholder} error={error} /> : null}
    </GenericModal>
  )
}
