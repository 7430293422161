import React from 'react'
import { useFetch } from '../../../components/component-items/helpers'
// Components
import CardLoading from '../../../components/component-items/loading-popover'
import { StatusBarStyled } from '../../../components/component-items/status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons'
import { faCalendarStar, faUserShield } from '@fortawesome/pro-duotone-svg-icons'

export const StatusBar = () => {
  const url = '/api/core/users/profile_data/'
  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})
  const firstName = loaded ? resp?.firstName : ''
  const lastName = loaded ? resp?.lastName : ''
  const dateJoined = loaded ? resp?.dateJoined : ''
  const lastLogin = loaded ? resp?.lastLogin : ''
  const companyName = loaded ? resp?.companyName : ''
  return (
    <div className="row">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#51e2d3' }} />
            </div>
            <div className="status-text">
              <div>
                Last Login: <strong>{lastLogin}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faUserShield} style={{ color: 'blue' }} />
            </div>
            <div className="status-text">
              <h5 style={{ margin: 0 }}>
                {firstName} {lastName}
              </h5>
              {companyName}
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text">
              Date Joined: <strong>{dateJoined}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '1em' }}>
              <FontAwesomeIcon icon={faCalendarStar} style={{ color: 'blue' }} />
            </div>
          </div>
          {!loaded && <CardLoading text={placeholder} error={error} />}
        </StatusBarStyled>
      </div>
    </div>
  )
}
