import React, { useState, useRef } from 'react'
import { usePost, notify } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { Form, Col, Row, Modal } from 'react-bootstrap'

// Types
import { ChangePasswordProps } from './types'

export const ChangePassword = ({ open, setOpen }: ChangePasswordProps) => {
  const defaultSettings = {
    password: '',
    passwordRepeat: '',
  }

  const [settings, setSettings] = useState(defaultSettings)
  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement>(null)

  const {
    state: { csrf },
  } = globalState()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    if (settings.password !== settings.passwordRepeat) {
      notify({ type: 'error', message: 'Passwords do not match' })
      return
    }

    setValidated(true)

    var formData = new window.FormData()
    // @ts-ignore
    formData.append('password', settings.password)

    const result = await usePost('/api/core/users/change_password/', formData, csrf, false, false, true)
    if (!result.success && result.error) {
      notify({ type: 'error', message: result.error })
    } else {
      notify({ type: 'success', message: 'Password updated successfully' })
      setOpen(false)
    }
  }

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} id="changePasswordForm">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
        </Modal.Header>
        <div style={{ padding: '2em', maxWidth: 400, margin: 'auto' }}>
          <Row style={{ alignItems: 'baseline' }}>
            <Form.Group as={Col} md="12" controlId="password" className="required">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={settings.password}
                onChange={(e) => setSettings({ ...settings, password: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please add a password.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ alignItems: 'baseline' }}>
            <Form.Group as={Col} md="12" controlId="passwordRepeat" className="required">
              <Form.Label>Repeat password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Repeat password"
                value={settings.passwordRepeat}
                onChange={(e) => setSettings({ ...settings, passwordRepeat: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <GlobalStyles.Button onClick={handleSubmit} style={{ minWidth: 150, margin: '2em 0', float: 'right' }}>
            Update
          </GlobalStyles.Button>
        </div>
      </Form>
    </Modal>
  )
}
