import React, { useEffect, useState } from 'react'
import { useFetch, usePost, usePut } from '../../../components/component-items/helpers'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { Form, Modal, InputGroup } from 'react-bootstrap'
import { globalState } from '../../../store'
import Checkbox from '../../../components/component-items/checkbox'

export const EDIRetailerModal = ({ open, setOpen, retailer }: any) => {
  const {
    state: { csrf },
  } = globalState()

  const [validated, setValidated] = useState(false)
  const [retailerSettings, setRetailerSettings] = useState(retailer || {})

  const { response: resp, loaded, error } = useFetch('/api/edi/retailer/orderful_id_list/', {})
  const orderfulIds = resp?.orderfulIds || []

  useEffect(() => {
    setRetailerSettings(retailer || {})
  }, [open])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement | HTMLButtonElement | HTMLAnchorElement>) => {
    const form: any = event.currentTarget

    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    if (form?.checkValidity() === false || !retailerSettings.orderfulId) {
      return
    }
    if (retailer) {
      usePut(`/api/edi/retailer/${retailerSettings.id}/`, retailerSettings, csrf)
    } else {
      usePost(`/api/edi/retailer/`, retailerSettings, csrf)
    }
  }
  return (
    <Modal show={open} onHide={() => setOpen(false)} size="lg" centered>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{retailer ? 'Edit Retailer' : 'Add Retailer'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: '0 auto', maxWidth: 400 }}>
            <Form.Group className="required">
              <Form.Label style={{ display: 'inline-flex' }}>
                <GlobalStyles.Tooltip
                  className="subheader__button button"
                  data-title="Used for GS1 Labels."
                  style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
                >
                  Orderful ID
                </GlobalStyles.Tooltip>
              </Form.Label>
              <Form.Select
                value={retailerSettings.orderfulId}
                isInvalid={validated && !retailerSettings.orderfulId}
                onChange={(e) => setRetailerSettings((s: any) => ({ ...s, orderfulId: e.target.value }))}
              >
                <option hidden>-- Select --</option>
                {Object.keys(orderfulIds).map((o, id) => (
                  <option value={o} key={id}>
                    {orderfulIds[o]}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please select a valid orderful reference ID.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="required" style={{ marginTop: '1em' }}>
              <Form.Label style={{ display: 'inline-flex' }}>
                <GlobalStyles.Tooltip
                  className="subheader__button button"
                  data-title="How it will be displayed in ShippingTree."
                  style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
                >
                  Retailer Name
                </GlobalStyles.Tooltip>
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Retailer Name"
                value={retailerSettings.retailer}
                onChange={(e) => setRetailerSettings((s: any) => ({ ...s, retailer: e.target.value }))}
              />
              <Form.Control.Feedback type="invalid">Please enter the retailers name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="required" style={{ marginTop: '1em' }}>
              <Form.Label style={{ display: 'inline-flex' }}>
                <GlobalStyles.Tooltip
                  className="subheader__button button"
                  data-title="Found in your routing guidelines."
                  style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}
                >
                  Retailer Reference ID
                </GlobalStyles.Tooltip>
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Reference ID"
                value={retailerSettings.referenceId}
                onChange={(e) => setRetailerSettings((s: any) => ({ ...s, referenceId: e.target.value }))}
              />
              <Form.Control.Feedback type="invalid">
                Please enter the reference ID of the retailer.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group style={{ margin: '1.5em 0' }}>
              <InputGroup>
                <Checkbox
                  selected={retailerSettings.usesGs1Labels}
                  setSelected={() => setRetailerSettings((s: any) => ({ ...s, usesGs1Labels: !s.usesGs1Labels }))}
                />
                <p style={{ padding: '0 1em', marginBottom: 0, lineHeight: '24px' }}>Uses GS1 Labels</p>
              </InputGroup>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <GlobalStyles.Button style={{ minWidth: 125 }}>Save</GlobalStyles.Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
