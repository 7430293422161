import React, { useState, useEffect } from 'react'
import { Tag } from './styles'
import { useFetch, usePost, notify } from '../../../../components/component-items/helpers'
import { globalState } from '../../../../store'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import ButtonDropdown from '../../../../components/component-items/button-dropdown'
import { TableRow } from '../../../orders/styles'
import { TableCell } from '../../../../components/component-items/datatable'
import { HoverNotes } from '../../../../components/component-items/hover-notes'
import { SMS } from './sms'
import { LargeButton } from '../../../../components/component-items/buttons'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faEdit, faPlusHexagon } from '@fortawesome/pro-duotone-svg-icons'

import {
  TwoFactorAuthenticationDescriptions,
  TwoFactorAuthenticationIcons,
  TwoFactorAuthenticationDisplays,
} from '../../../../enums'

export const TwoFactorOptions = () => {
  const [primaryMethod, setPrimaryMethod] = useState<any>({})
  const [sort, setSort] = useState('')
  const [smsOpen, setSmsOpen] = useState(false)
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('')
  const sortOptions: any = {
    'SMS/Text Message': 'sms',
    // "Security Keys (YubiKey)": "yubikey",
    // "One-Time Password": "totp",
  }
  const {
    state: { csrf, userID },
  } = globalState()

  const url = `/api/core/users/${userID}/2fa/?primaryMethod=${sort}?currentPhoneNumber=${currentPhoneNumber}`

  const { response: resp, error, loaded, placeholder }: any = useFetch(url, {})
  const { count: total, results }: any = loaded && resp ? resp : {}

  useEffect(() => {
    if (loaded && total > 0) {
      const pm = results.find((result: any) => result.is_primary)
      const sms = results.find((result: any) => result.mfa_type === 'sms')
      if (pm) {
        setPrimaryMethod(pm)
        setSort(Object.keys(sortOptions).find((key) => sortOptions[key] === pm.mfa_type) || '')
      }
      if (sms) setCurrentPhoneNumber(sms.phone_number)
    }
  }, [loaded])

  const updatePrimaryMethod = async (new_method: any) => {
    const resp: any = await usePost(`/api/core/users/${userID}/2fa/${new_method.id}/set_primary/`, {}, csrf, false)
    if (resp.id) {
      setPrimaryMethod(resp)
      setSort(TwoFactorAuthenticationDisplays[resp.mfa_type])
    }
  }

  useEffect(() => {
    if (sort && primaryMethod && TwoFactorAuthenticationDisplays[primaryMethod.mfa_type] !== sort) {
      const new_pm = results.find((result: any) => TwoFactorAuthenticationDisplays[result.mfa_type] === sort)
      updatePrimaryMethod(new_pm)
    }
  }, [sort])

  return (
    <GlobalStyles.FullPageCard style={{ minHeight: 500 }}>
      <GlobalStyles.CardHeader style={{ marginTop: 10 }}>
        <GlobalStyles.CardTitle>
          <h3>Two-factor Authentication</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar style={{ padding: 10 }}>
          {results?.length > 0 && (
            <>
              <HoverNotes
                description="Set your primary method to use for two-factor authentication when signing into ShippingTree."
                title="Primary 2FA Method:"
              />
              <ButtonDropdown
                sort={sort}
                setSort={setSort}
                options={Object.keys(sortOptions)}
                height={35}
                width={200}
              />
            </>
          )}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <GlobalStyles.DataTable>
        <thead>
          <tr>
            <th>
              <span className="text" style={{ marginLeft: '10px' }}>
                Two-factor Method
              </span>
            </th>
            <th>
              <span className="text center">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRow>
            <TableCell
              input={
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                  <FontAwesomeIcon
                    size="2x"
                    icon={TwoFactorAuthenticationIcons['sms']}
                    style={{ marginRight: '10px', marginBottom: '14px' }}
                  />
                  <h5>
                    {TwoFactorAuthenticationDisplays['sms']}
                    {currentPhoneNumber && <Tag className="configured"> Configured </Tag>}
                    {primaryMethod && primaryMethod.mfa_type === 'sms' && <Tag className="primary"> Primary </Tag>}
                    <br />
                    <span style={{ fontSize: '.7em' }}>{TwoFactorAuthenticationDescriptions['sms']}</span>
                  </h5>
                </div>
              }
            />
            <TableCell
              center
              input={
                <div className="dropdown__container">
                  <LargeButton onClick={() => setSmsOpen(true)}>
                    <GlobalStyles.Tooltip data-title={currentPhoneNumber ? 'Edit Method' : 'Add Method'}>
                      {currentPhoneNumber ? (
                        <FontAwesomeIcon icon={faEdit} />
                      ) : (
                        <FontAwesomeIcon icon={faPlusHexagon} />
                      )}
                    </GlobalStyles.Tooltip>
                  </LargeButton>
                </div>
              }
            />
          </TableRow>
          <SMS
            smsOpen={smsOpen}
            setSmsOpen={setSmsOpen}
            method={primaryMethod}
            currentPhoneNumber={currentPhoneNumber}
            setCurrentPhoneNumber={setCurrentPhoneNumber}
          />
        </tbody>
      </GlobalStyles.DataTable>
      {!loaded && <CardLoading text={placeholder} error={error} />}
    </GlobalStyles.FullPageCard>
  )
}
