import React, { useState, useRef, FunctionComponent } from 'react'
import { globalState } from '../../../store'
import { Link } from 'react-router-dom'
import { useClick, useFetch, usePost } from '../../component-items/helpers'

// Components
import { Styled } from './styles'

// Types
import { NavItemProps } from './types'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPortrait } from '@fortawesome/pro-solid-svg-icons'
import { faCog, faIdBadge, faShieldCheck } from '@fortawesome/pro-duotone-svg-icons'

const NavItem: FunctionComponent<NavItemProps> = ({ url, title, icon }) => (
  <Styled.NavItem>
    <Link to={url}>
      <span className="nav__link-icon">
        <i>
          <FontAwesomeIcon icon={icon} />
        </i>
      </span>
      <span className="nav__link-text">{title}</span>
    </Link>
  </Styled.NavItem>
)

export const UserBar = () => {
  const [isOpen, setOpen] = useState(false)
  const node = useRef(null)

  const {
    state: { userFullName, userCompany, csrf },
  } = globalState()

  useClick(node, setOpen)

  const { response: res, loaded } = useFetch('/api/core/users/profile_data/', {})
  const image = loaded ? (res as any)?.image : ''
  const photo = image ? (
    <img alt="Pic" src={image} />
  ) : (
    <div className="photo-placeholder">
      <i>
        <FontAwesomeIcon icon={faPortrait} />
      </i>
    </div>
  )

  const handleLogout = async () => {
    console.log('attempting')
    const response: any = await usePost('/api/logout/', {}, csrf, false, true, true)
    console.log(response)
    if (response?.ok) {
      window.location.href = '/'
    }
  }

  return (
    <Styled.UserBar ref={node}>
      <Styled.HeaderButton onClick={() => setOpen(!isOpen)} aria-haspopup="true">
        <div className={'header__topbar-user ' + (isOpen ? 'active' : '')}>
          <span className="header__topbar-username">{userFullName}</span>
          {photo}
        </div>
      </Styled.HeaderButton>
      {isOpen ? (
        <div className="user__dropdown-menu">
          <div className="user-card">
            <div className="user-card__wrapper">
              <div className="user-card__pic">{photo}</div>
              <div className="user-card__details">
                <div className="user-card__name">{userFullName}</div>
                <div className="user-card__position">{userCompany || 'Shipping Tree'}</div>
              </div>
            </div>
          </div>
          <ul>
            <NavItem url={'/user/profile/'} title={'My Profile'} icon={faIdBadge} />
            <NavItem url={'/company/settings/'} title={'Settings'} icon={faCog} />
            <li className="nav__separator"></li>
            <li className="nav__button">
              <div className="logout" onClick={handleLogout}>
                Sign Out
              </div>
            </li>
          </ul>
        </div>
      ) : null}
    </Styled.UserBar>
  )
}
