import styled from 'styled-components'

export const OrderInfo = styled.div`
  width: 50%;
  margin: 0 auto 4em;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #f2f3f8;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  height: 100%;
  min-height: 300px;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
`

export const ProductImage = styled.div`
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14em;
  button {
    position: absolute;
    margin: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }
  &:hover button,
  &:hover .shade {
    opacity: 1;
  }
  .shade {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.11);
  }
`
