import React, { useState, useRef } from 'react'
import { globalState } from '../../../../store'

// Components
import GlobalStyles from '../../../../components/component-items/styles'
import { Form, Row, Modal } from 'react-bootstrap'
import GenericModal from '../../../../components/component-items/modal'
import { notify, usePost, useDelete } from '../../../../components/component-items/helpers'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

// Types
import { SecuritySettingsProps } from './types'

export const SMS = ({
  userID,
  smsOpen,
  method,
  setSmsOpen,
  currentPhoneNumber,
  setCurrentPhoneNumber,
}: SecuritySettingsProps) => {
  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement>(null)
  const [phone, setPhone] = useState('')
  const [token, setToken] = useState('')
  const [submittedPhone, setSubmittedPhone] = useState(false)
  const {
    state: { csrf },
  } = globalState()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    const form = e.currentTarget
    setValidated(true)
    if (form.checkValidity() === false) {
      return
    } else {
      if (!submittedPhone) {
        // Send phone number to the server
        const response = await usePost('/api/2fa/setup/phone/', { phone: '+' + phone, userID: userID }, csrf, false)
        if (response.success) {
          setSubmittedPhone(true)
        }
      } else {
        // Send token to the server
        const response = await usePost(
          '/api/2fa/verify/phone/',
          { phone: '+' + phone, token: token, userID: userID },
          csrf,
          false
        )
        if (response.success) {
          notify({ type: 'success', message: 'SMS/Text Message setup successfully' })
          if (userID) {
            // Logged in user, direct to dashboard
            window.location.href = '/'
          }
          setCurrentPhoneNumber(phone)
          setSubmittedPhone(false)
          setPhone('')
          setToken('')
          setSmsOpen(false)
        }
      }
    }
    setValidated(false)
  }

  return (
    <GenericModal
      show={smsOpen}
      heading={<Modal.Title>SMS/Text Message Setup</Modal.Title>}
      onHide={() => setSmsOpen(false)}
      // size="sm"
      buttons={
        <div style={{ margin: '1em 0' }}>
          {currentPhoneNumber && (
            <GlobalStyles.Button
              onClick={() => useDelete(`/api/core/users/${method?.user_id}/2fa/${method?.id}/`, csrf)}
              className="secondary"
              style={{ minWidth: 150 }}
            >
              Reset
            </GlobalStyles.Button>
          )}
          {!currentPhoneNumber && (
            <GlobalStyles.Button onClick={handleSubmit} style={{ minWidth: 150 }}>
              Submit
            </GlobalStyles.Button>
          )}
        </div>
      }
    >
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} id="profile-form">
        <div style={{ padding: '2em', maxWidth: 400, margin: 'auto' }}>
          <Row>
            {currentPhoneNumber ? (
              <Form.Group>
                <Form.Label>Current Phone</Form.Label>
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                  }}
                  inputStyle={{ width: '100%', backgroundColor: 'lightgrey' }}
                  country={'us'}
                  value={currentPhoneNumber}
                  disabled
                />
              </Form.Group>
            ) : null}
            {!currentPhoneNumber && (
              <Form.Group>
                <Form.Label>{currentPhoneNumber ? 'New Phone' : 'Phone'} </Form.Label>
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true,
                  }}
                  inputStyle={{ width: '100%' }}
                  country={'us'}
                  value={phone}
                  onChange={(phone: string) => setPhone(phone)}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your phone number to receive a token.
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Row>
          {submittedPhone && (
            <Row>
              <Form.Group>
                <Form.Label>Token</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter token"
                  value={token}
                  onChange={(e: any) => setToken(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please enter the token you received.</Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}
          {/* <GlobalStyles.Button onClick={handleSubmit} style={{ minWidth: 150, margin: '2em 0', float: 'right' }}>
            Submit
          </GlobalStyles.Button> */}
        </div>
      </Form>
    </GenericModal>
  )
}
