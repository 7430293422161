import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useFetch, usePost } from '../../components/component-items/helpers'
import { globalState } from '../../store'
import { BackgroundLayer, FilterListItem, DateRangeStyled } from './styles'
import { StatusBoxStyled } from '../../components/component-items/status-box'

// Components
import GlobalStyles from '../../components/component-items/styles'
import Pager, { Pages } from '../../components/component-items/pager'
import { TableCell, THSort } from '../../components/component-items/datatable'
import { SearchBox } from '../../components/component-items/search'
import GenericModal from '../../components/component-items/modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

// Modals
import { EDITransactionModal } from './transaction-modal'

// DateRange Component
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons'

import { FilterTransactionsProps } from './types'
import { formatDate } from '../../components/component-items/utils'
import { EDITransactionStatus } from '../../enums'
import styled, { useTheme } from 'styled-components'
import { faCode, faExternalLink, faFileTimes, faInboxIn, faInboxOut, faRedo } from '@fortawesome/pro-duotone-svg-icons'
import { LargeButton } from '../../components/component-items/buttons'
import CardLoading from '../../components/component-items/loading-popover'

const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
    div[id^='react-select-3-option'] {
      &:hover {
        background-color: #deebff;
      }
      :not(:hover) {
        transition: background-color 0.1s linear;
        -webkit-transition: background-color 0.1s linear;
      }
    }
  }
  div[id^='react-select-3']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-'][id$='-group-'][id*='-heading']:not(:hover) {
    background-color: transparent;
  }
  div[id^='react-select-3-group']:hover + div > div {
    background-color: #deebff !important;
  }
`

export const FilterTransactions = ({ open, setOpen, setFilter, categories, search }: FilterTransactionsProps) => {
  const { response: respRetailer, loaded: loadedRetailer } = useFetch('/api/edi/retailer', {})
  const since = new Date()
  since.setDate(since.getDate() - 14)
  const [state, setState] = useState([
    {
      startDate: since,
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [statuses, setStatuses] = useState([])
  const [transactionTypes, setTransactionTypes] = useState([])
  const [retailers, setRetailers] = useState([])
  const statusOptions = [
    { value: 'PENDING', label: 'Pending' },
    { value: 'PROCESSING', label: 'Processing' },
    { value: 'SUBMITTED', label: 'Submitted' },
    { value: 'COMPLETED', label: 'Completed' },
    { value: 'FAILED', label: 'Failed' },
    { value: 'REJECTED', label: 'Rejected' },
  ]
  const transactionTypeOptions = [
    { value: '940_WAREHOUSE_SHIPPING_ORDER', label: '940 Warehouse Shipping Order' },
    { value: '945_WAREHOUSE_SHIPPING_ADVICE', label: '945 Warehouse Shipping Advice' },
  ]
  const retailerOptions = loadedRetailer
    ? respRetailer.results.map((r: any) => ({ value: r.id, label: r.retailer }))
    : []

  useEffect(() => {
    var startDate = state[0].startDate.toLocaleDateString('en-US')
    var endDate = state[0].endDate.toLocaleDateString('en-US')
    setStartDate(startDate)
    setEndDate(endDate)
  }, [state])

  function handleSubmit() {
    setFilter({
      start_date: startDate.replace(/\//g, '-'),
      end_date: endDate.replace(/\//g, '-'),
      filter_statuses: statuses?.map((status: any) => status.value).join(','),
      transaction_types: transactionTypes?.map((status: any) => status.value).join(','),
      retailers: retailers?.map((status: any) => status.value).join(','),
    })
    setOpen(false)
  }

  return (
    <GenericModal
      heading={'Filter Transactions'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <>
          <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => handleSubmit()}>
            Filter Results
          </GlobalStyles.Button>
        </>
      }
    >
      <div style={{ margin: '1em' }}>
        <FilterListItem>
          <Form.Label>Filter By Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={startDate + ' - ' + endDate}
              onChange={(e) => null}
              onFocus={() => {
                const startDate = state[0].startDate.toLocaleDateString('en-US')
                setStartDate(startDate)
                const endDate = state[0].endDate.toLocaleDateString('en-US')
                setEndDate(endDate)
              }}
              placeholder="Choose A Date"
              aria-describedby="inputGroupAppend"
              required
            />
            <GlobalStyles.ClearInputButton
              onClick={() => {
                setStartDate('')
                setEndDate('')
              }}
            >
              Clear
            </GlobalStyles.ClearInputButton>
            <InputGroup.Text id="inputGroupAppend">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">Please choose a date range to filter by</Form.Control.Feedback>
          </InputGroup>
        </FilterListItem>
        {startDate || endDate ? (
          <DateRangeStyled>
            <DateRangePicker
              onChange={(item: any) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={new Date(2018, 1, 1)}
              maxDate={new Date()}
              editableDateInputs={true}
              ranges={state}
              direction="horizontal"
              className="hide-in-percy"
            />
          </DateRangeStyled>
        ) : null}
        <FilterListItem>
          <Form.Label>Filter by Status</Form.Label>
          <MultiSelect>
            <Select
              options={statusOptions}
              value={statuses}
              onChange={(option: any) => {
                return setStatuses(option)
              }}
              closeMenuOnSelect={false}
              isMulti
              className="basic-multi-select"
            />
          </MultiSelect>
        </FilterListItem>
        <FilterListItem>
          <Form.Label>Filter by Retailer</Form.Label>
          <MultiSelect>
            <Select
              options={retailerOptions}
              value={retailers}
              onChange={(option: any) => {
                return setRetailers(option)
              }}
              closeMenuOnSelect={false}
              isMulti
              className="basic-multi-select"
            />
          </MultiSelect>
        </FilterListItem>
        <FilterListItem>
          <Form.Label>Filter by Transaction type</Form.Label>
          <MultiSelect>
            <Select
              options={transactionTypeOptions}
              value={transactionTypes}
              onChange={(option: any) => {
                return setTransactionTypes(option)
              }}
              closeMenuOnSelect={false}
              isMulti
              className="basic-multi-select"
            />
          </MultiSelect>
        </FilterListItem>
      </div>
    </GenericModal>
  )
}

const Tooltip = styled(GlobalStyles.Tooltip)`
  &:before {
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

export const EDITransactions = () => {
  const {
    state: { actAs, csrf },
  } = globalState()

  const theme = useTheme() as any

  const tableRowOptions = [20, 100, 250, 500]
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(tableRowOptions[0])

  const [sort, setSort] = useState({})
  const [filter, setFilter] = useState({})
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)

  const url = '/api/edi/'
  const filterOptions = Object.keys(filter)
    .map((key) => key + '=' + filter[key as keyof typeof filter])
    .join('&')
  const queryString = `?offset=${rows * (page - 1)}&limit=${rows}&q=${encodeURIComponent(search)}&order_by=${Object.keys(
    sort
  )}&sort=${Object.values(sort)}&${filterOptions}`
  const { response: resp, loaded, error, placeholder } = useFetch(url + queryString, {})

  useEffect(() => {
    setLoading(!loaded)
  }, [loaded])

  const transactions = loaded ? resp.results : []

  const total = loaded ? resp.count : 0
  var totalPages = loaded && total ? Math.ceil(total / rows) : 1
  const categories: any = []

  const statusColor = (status: string) => {
    switch (status) {
      case EDITransactionStatus.Pending:
        return 'yellow'
      case EDITransactionStatus.Processing:
        return 'purple'
      case EDITransactionStatus.Submitted:
        return 'blue'
      case EDITransactionStatus.Completed:
        return 'green'
      case EDITransactionStatus.Failed:
        return 'red'
      case EDITransactionStatus.Rejected:
        return 'black'
      default:
        return 'silver'
    }
  }

  const [currentTransaction, setCurrentTransaction] = useState<any>({})
  const [transactionModal, setTransactionModal] = useState(false)
  const openModal = (t: any) => {
    setCurrentTransaction(t)
    setTransactionModal(true)
  }

  return (
    <BackgroundLayer style={{ display: 'block', position: 'relative', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            EDI Transactions<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button className="secondary" style={{ height: 40 }} onClick={() => setOpen(true)}>
            Filter
          </GlobalStyles.Button>
          <FilterTransactions {...{ open, setOpen, setFilter, categories, search }} />
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} style={{ width: 150 }} updateURL />
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 500, width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <THSort title={'ID'} {...{ sort, setSort }} />
              <THSort title={'Created'} center {...{ sort, setSort }} />
              {!actAs && <THSort title={'Merchant'} center {...{ sort, setSort }} />}
              <THSort title={'Retailer'} center {...{ sort, setSort }} />
              <THSort title={'Type'} center {...{ sort, setSort }} />
              <THSort title={'Status'} center {...{ sort, setSort }} />
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t: any, id: number) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell
                  input={
                    <>
                      <div>
                        Orderful:{' '}
                        {t?.orderful_id && (
                          <a
                            href={`https://ui.orderful.com/transactions/${encodeURIComponent(t.orderful_id)}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t.orderful_id}
                          </a>
                        )}
                      </div>
                      {t.order && (
                        <div>
                          Order:{' '}
                          <Link to={`/order/${t.order}/`} rel="noreferrer" target="_blank">
                            {t.order}
                          </Link>
                        </div>
                      )}
                    </>
                  }
                />
                <TableCell>{formatDate(t.created)}</TableCell>
                {!actAs && <TableCell center>{t.company}</TableCell>}
                <TableCell center>{t.retailer}</TableCell>
                <TableCell
                  input={
                    <>
                      {t.type === '940' ? (
                        <FontAwesomeIcon icon={faInboxIn} color={'blue'} />
                      ) : t.type === '945' ? (
                        <FontAwesomeIcon icon={faInboxOut} color={theme.colors.freshEggplant} />
                      ) : null}
                      <span style={{ paddingLeft: '.5em' }}>{t.type}</span>
                    </>
                  }
                  center
                />
                <TableCell
                  input={
                    <StatusBoxStyled className={statusColor(t.status)} style={{ minWidth: 75, margin: 'auto' }}>
                      {t.status}
                    </StatusBoxStyled>
                  }
                  center
                />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container" style={{ display: 'flex', justifyContent: 'center' }}>
                      <>
                        <Tooltip className="subheader__button button" data-title="View Transaction Message">
                          <LargeButton onClick={() => openModal(t)}>
                            <FontAwesomeIcon icon={faCode} />
                          </LargeButton>
                        </Tooltip>
                        {t.status === EDITransactionStatus.Failed && t.type === '940' && (
                          <>
                            <Tooltip className="subheader__button button" data-title="Reprocess">
                              <LargeButton onClick={() => usePost(`/api/edi/${t.id}/reprocess/`, {}, csrf)}>
                                <FontAwesomeIcon icon={faRedo} color="blue" />
                              </LargeButton>
                            </Tooltip>
                            <Tooltip className="subheader__button button" data-title="Reject">
                              <LargeButton onClick={() => usePost(`/api/edi/${t.id}/reject/`, {}, csrf)}>
                                <FontAwesomeIcon icon={faFileTimes} color="#FD397A" />
                              </LargeButton>
                            </Tooltip>
                          </>
                        )}
                      </>
                    </div>
                  }
                />
              </GlobalStyles.TableRow>
            ))}
          </tbody>
          {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
        </GlobalStyles.DataTable>
      </div>
      <GlobalStyles.CardFooter>
        <Pages page={page} setPage={setPage} show={3} total={totalPages} />
        <Pager options={tableRowOptions} page={page} total={total} rows={rows} setRows={setRows} />
      </GlobalStyles.CardFooter>
      <EDITransactionModal open={transactionModal} setOpen={setTransactionModal} transaction={currentTransaction} />
    </BackgroundLayer>
  )
}
