import { faComment, faKey, faLock } from '@fortawesome/pro-light-svg-icons'

export enum OrderStatus {
  Cancelled = 10,
  Project = 40,
  Paused = 70,
  Backordered = 80,
  Received = 90,
  Processing = 100,
  Picked = 110,
  Packed = 120,
  LabelPurchaseFail = 145,
  RateLimitExceeded = 150,
  LabelPurchased = 160,
  Shipped = 200,
  Delivered = 300,
}

export enum BillingStatus {
  Debit = 'N',
  Credit = 'C',
  Processing = 'R',
  Picking = 'K',
  Postage = 'P',
  Packaging = 'Z',
  RushOrder = 'O',
  OrderCancellation = 'X',
  ManualOrderBilling = 'B',
  CasepackPallet = 'G',
  CasepackMaster = 'H',
  CasepackInner = 'I',
  Storage = 'S',
  Monthly = 'M',
  Project = 'J',
  ASNNonCompliance = 'A',
  ReturnProcessing = 'E',
  ReturnPicking = 'F',
  DocumentPrinting = 'D',
  LabelPrinting = 'L',
  LotControl = 'Q',
  Payment = 'T',
  PaymentFees = 'payment_fees',
}

export enum LotControlField {
  ReceivedDate = 'received_date',
  ExpiryDate = 'expiry_date',
  BestBeforeDate = 'best_before_date',
  ManufactureDate = 'manufacture_date',
}

export enum ShipNoticeStatus {
  Project = 20,
  ReturnNew = 50,
  ReturnSentToCustomer = 60,
  ReturnLabelPurchaseFail = 70,
  InTransit = 80,
  Arrived = 90,
  Processing = 100,
  Received = 110,
  Completed = 200,
}

export enum NotificationType {
  TypeProductVariant = 'product_variant',
  TypeOrder = 'order',
  TypeASN = 'asn',
  TypeProject = 'project',
  TypeSystemUpdate = 'system_update',
  TypeFeatureUpdate = 'feature_update',
  TypeMaintenance = 'maintenance',
  TypeSystemAlert = 'system_alert',
}

export enum RecurrenceCategory {
  TypeBatching = 'batching',
  TypeBilling = 'billing',
  TypeReporting = 'reporting',
}

export enum RecurrenceTaskName {
  CreateBillingTransaction = 'billing.tasks.create_billing_transaction',
  InventoryReport = 'inventory.reports.inventory_report',
  InventorySnapshot = 'inventory.reports.inventory_snapshot',
  OrderReport = 'order.tasks.scheduled_order_list_download',
  ShipmentReport = 'order.tasks.scheduled_shipment_list_download',
}

export enum RecurrenceTaskCategory {
  CategoryBatching = 'batching',
  CategoryBilling = 'billing',
  CategoryReporting = 'reporting',
}

export enum AsyncTaskStatus {
  Pending = 'pending',
  Started = 'started',
  Success = 'success',
  Failure = 'failure',
  Retry = 'retry',
  Revoked = 'revoked',
}

export enum EDITransactionStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Submitted = 'Submitted',
  Completed = 'Completed',
  Failed = 'Failed',
  Rejected = 'Rejected',
}

export const TwoFactorAuthenticationMethods = {
  SMS: 'sms',
  YUBIKEY: 'yubikey',
  TOTP: 'totp',
}

export const TwoFactorAuthenticationDisplays = {
  [TwoFactorAuthenticationMethods.SMS]:
    'SMS/Text Message',
  [TwoFactorAuthenticationMethods.YUBIKEY]:
    'Security Keys (YubiKey)',
  [TwoFactorAuthenticationMethods.TOTP]: 'One-Time Password',
}


export const TwoFactorAuthenticationDescriptions = {
  [TwoFactorAuthenticationMethods.SMS]:
    'You will receive one-time codes to the phone number registered with your account.',
  [TwoFactorAuthenticationMethods.YUBIKEY]:
    'Security keys are physical keys that must be plugged into your device to log in.',
  [TwoFactorAuthenticationMethods.TOTP]: 'Generates a temporary one-time and time-sensitive code.',
}

export const TwoFactorAuthenticationIcons = {
  [TwoFactorAuthenticationMethods.SMS]: faComment,
  [TwoFactorAuthenticationMethods.YUBIKEY]: faKey,
  [TwoFactorAuthenticationMethods.TOTP]: faLock,
}

export enum OrderAutomationEventTypes {
  Now = 'now',
  OrderCreated = 'order_created',
  OrderStatus = 'order_status',
}
