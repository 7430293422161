import styled, { keyframes } from 'styled-components'
import BootstrapCarousel from 'react-bootstrap/Carousel'

export const move = keyframes`
    from { background-position: 0 0; }
    to { background-position: -400px 0; }
`

export const PostPage = styled.div<{ background?: string }>`
  background: #242939 url('/api/static/assets/login/background.png') 100px 100px repeat;
  background-size: 400px;
  color: ${(props) => props.theme.colors.white};
  width: 100vw;
  height: 100vh;
  animation: ${move} 40s linear infinite;
`

export const Background = styled.div<{ background?: string }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  box-shadow: rgba(228, 255, 248, 0.13) 5px 8px 20px 0px;
  border-radius: 5px;
`

export const SignIn = styled.div`
  display: flex;
  min-width: 900px;
  min-height: 500px;
`

export const TwoFactorAuth = styled.div`
  width: 500px;
  min-height: 500px;
`

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 50%;
  height: 100%;
  padding: 2em 4em;
`

export const InfoPanel = styled.div`
  width: 50%;
  background: url('/api/static/assets/login/banner.png') bottom no-repeat;
  background-size: cover;
`

export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid grey;
  &:focus {
    outline: none;
  }
`

export const Logo = styled.img`
  width: 80%;
  height: auto;
  margin: 1em auto 0;
`

export const H2 = styled.h2`
  text-align: center;
  padding: 2em 0 0;
  font-size: 1.5em;
  color: #424c69;
`

export const H3 = styled.h3`
  text-align: center;
  padding: 3em 0;
  font-size: 1em;
  color: #424c69;
`

export const P = styled.p`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin: 2em auto;
    width: 150px;
    border-radius: 50px;
  }
`

export const Carousel = styled(BootstrapCarousel)`
  padding: 2em 3em !important;
  min-height: 200px !important;
  .carousel-indicators button {
    background-color: #000 !important;
  }
  .carousel-item {
    min-height: 150px !important;
  }
`
